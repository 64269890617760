<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-form  ref="form" v-model="isValid">
      <v-card class="px-4 py-4">
        <v-card-title class="justify-center">
          <span class="font-24">ชำระเงินสำหรับเข้าร่วมหลักสูตรอบรม</span>
        </v-card-title>
        <v-card-text class="py-0 mt-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0 my-0">
                <small class="text--danger font-14" v-if="register_type === 'per_course'">
                  *การสมัครเข้าร่วมหลักสูตรอบรมเเบบรายครั้ง จะสามารถใช้งานได้เฉพาะหลักสูตรอบรมที่ทำการสมัครเท่านั้น
                </small>
              </v-col>
              <v-col cols="12" md="5">
                <img src="../../assets/images/payment/qr.png" class="d-block mx-auto" alt="Qr payment" width="200px"
                  height="auto" />
              </v-col>
              <v-col cols="12" md="7" class="d-flex flex-column justify-center">
                <p class="font-w-500 font-20">
                  ชื่อบัญชี: OSCC
                </p>
                <p class="font-w-500 font-16">
                  ธนาคาร: กสิกรไทย หมายเลขบัญชี: 123-4-56789-0
                </p>
                <p class="font-w-500 font-24">
                  ชำระเงินจำนวน <span class="main-blue-text">
                    {{ Number(getCoursePrice()).toFixed(2) }}
                  </span> บาท
                </p>
              </v-col>
            </v-row>
            <div class="mt-4">
              <div class="font-w-500">หลักฐานการชำระเงิน</div>

              <v-layout mt-2 wrap justify-center>
                <v-flex class="mr-4 mt-sm-0 pr-3 pr-sm-0">
                  <input id="fileInput" ref="fileInput" class="d-none" type="file"
                    accept="image/png, image/jpg, image/jpeg" @input="onUploadImage" />
                  <v-file-input placeholder="อัปโหลดหลักฐานการชำระเงิน" show-size outlined
                    dense ref="fileInput" prepend-icon="" background-color="#fff" v-model="fileSlip"
                    accept="image/png, image/jpg, image/jpeg" :rules="rules" @change="onUploadImage" clearable
                    clear-icon="mdi-close-circle" class="font-14"
                    @click:clear="clearInput">
                  </v-file-input>
                </v-flex>
                <v-flex shrink>
                  <v-btn @click="onTriggleFileInput" depressed dark class="btn-secondary-blue">
                    อัปโหลด
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="white" text @click="submit" class="btn-blue-gradient font-20" large rounded>
            ยืนยัน
          </v-btn>
          <v-btn color="white" text @click="dialog = false" class="btn-secondary font-20" large rounded>
            ยกเลิก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">



export default {
  props: {
    value: Boolean,
    course_id: Number, 
  },
  data: () => ({
    register_type: "per_course",
    fileSlip: null,
    isValid: false,
    rules: [
      (value) => !!value || "กรุณาเลือกไฟล์!",
      (value) =>
        !value || value.size < 5000000 || "ขนาดไฟล์ต้องไม่เกิน 5 MB!",
    ],
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const now = new Date();

      const formData = new FormData();
      formData.append("image", this.fileSlip);
      formData.append("list_training_course_id", this.course_id);
      formData.append("payment_type", this.register_type);
      formData.append("user_id", this.$store.state.auth.user.id);
      formData.append("amount", this.getCoursePrice());
      formData.append("payment_date", now.toISOString().split("T")[0]);


      //emit loading
      this.$emit("loading", true);

      await this.$store.dispatch("Course/storePayment", formData).then((res) => {
        if(res.status === 201) {
          this.clearInput();
          this.dialog = false;
          this.$emit("loading", false);
        }else{
          this.$swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาลองใหม่อีกครั้ง",
          });
        }
        this.$emit("input", false);
        this.$emit("paymentSuccess", res);
      });
      

    },
    onUploadImage() {
      console.log(this.fileSlip);
    },
    onFileChange(e) {
      this.fileSlip = e.target.files[0];
      this.imageName = this.fileSlip.name;
    },
    getCoursePrice() {
      if(this.register_type === 'per_year') {
        return 500.00;
      }else {
        return 200.00;
      }
    },
    getYearlyExpireDate() {
      let $lastDay = new Date(new Date().getFullYear(), 11, 31);
      return $lastDay.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    onTriggleFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    clearInput() {
      this.imageName = "";
      this.fileSlip = null;
    },
  },
}
</script>

<style>
.text--danger {
  color: #FF7070;
}
.v-input--radio-group__input .v-radio{
  border: solid 1px #bcc4cb;
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.v-radio.theme--light.v-item--active{
  background: #EBF7FF;
  border: solid 1px var(--main-blue);
}
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  font-size: 14px;
}
</style>